import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import {
  Grid, Box, Typography, Link, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MyContext } from '../../pages/_app';

const PREFIX = 'AppFooter';

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    backgroundColor: '#194b8c',
    maxWidth: '100%',
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
  },
}));

export default function AppFooter() {
  const { t } = useTranslation();
  const phone = useMediaQuery('(min-width:600px)');
  const { printMode } = useContext(MyContext);
  return (
    (
      <Root>
        {!printMode && (
        <footer>
          <Box className={classes.root} position="relative">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Image
                  src="/img/kee-white.png"
                  alt="Λογότυπο Κεντρικής ένωσης επιμελητηρίων ελλάδος"
                  width={180}
                  height={60}
                />
              </Grid>
              <Grid item xs={12}>
                <a
                  href="https://www.uhc.gr/"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography style={phone ? { color: 'white' } : { color: 'white', fontSize: 14 }}>
                    ©&nbsp;
                    {`${t(
                      'ΚΕΝΤΡΙΚΗ ΕΝΩΣΗ ΕΠΙΜΕΛΗΤΗΡΙΩΝ ΕΛΛΑΔΟΣ',
                    )} ${moment().format('YYYY')}`}
                  </Typography>
                </a>
              </Grid>
              <Grid item xs={12}>
                <Typography style={phone ? { color: 'white' } : { color: 'white', fontSize: 14 }}>
                  Designed & Developed by
                  <Link
                    style={{ textDecoration: 'none', color: 'white' }}
                    target="_blank"
                    href="https://www.knowledge.gr/"
                  >
                    &nbsp;Knowledge AE
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={phone ? { color: 'white' } : { color: 'white', fontSize: 14 }}>
                  <Link
                    style={{ textDecoration: 'none', color: 'white' }}
                    target="_blank"
                    href="/privacyPolicy"
                  >
                    {t('Πολιτική Απορρήτου')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={phone ? { color: 'white' } : { color: 'white', fontSize: 14 }}>
                  <Link
                    style={{ textDecoration: 'none', color: 'white' }}
                    target="__blank"
                    href="cookiePolicy"
                  >
                    {t('Πολιτική Cookies')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </footer>
        )}
      </Root>
    )
  );
}
