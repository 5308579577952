import {
  React, useState, useContext, useEffect,
} from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import Image from 'next/image';
import Box from '@mui/material/Box';
// eslint-disable-next-line import/no-extraneous-dependencies
import Flags from 'country-flag-icons/react/3x2';
import useMediaQuery from '@mui/material/useMediaQuery';
// eslint-disable-next-line import/no-cycle
import Link from 'next/link';
import { MyContext } from '../../pages/_app';

const PREFIX = 'AppHeader';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  title: `${PREFIX}-title`,
  appBar: `${PREFIX}-appBar`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.logo}`]: {
    maxWidth: 160,
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.appBar}`]: {
    backgroundColor: '#194b8c',
  },
}));

export default function AppHeader() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState();
  const phone = useMediaQuery('(min-width:600px)');
  const { setLanguageFlag, printMode } = useContext(MyContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setCurrentLanguage(Cookies.get('next-i18next') ?? 'el');
  }, []);

  return (
    (
      <Root>
        {!printMode && (
        <div className={classes.root}>
          <AppBar
            className={classes.appBar}
            position="static"
            style={{ height: 95 }}
          >
            <Toolbar style={{ alignItems: 'center', height: 95 }}>
              {phone && (
              <>
                {currentLanguage === 'el' && (
                <a href="https://www.mindev.gov.gr/" target="_blank" rel="noreferrer">
                  <Typography
                    type="title"
                    color="inherit"
                    style={{
                      borderRight: '0.1em solid white',
                      padding: '0.5em',
                    }}
                  >
                    <Image
                      src="/logo-ypourgeiou-new-gr.png"
                      alt="greek republic ministry of development and investment"
                      width={180}
                      height={70}
                      className={classes.logo}
                      style={{ height: 56, paddingRight: 5, paddingLeft: 5 }}
                    />
                  </Typography>
                </a>
                )}
                {currentLanguage === 'en' && (
                <a href="https://www.mindev.gov.gr/" target="_blank" rel="noreferrer">
                  <Typography
                    type="title"
                    color="inherit"
                    style={{
                      borderRight: '0.1em solid white',
                      padding: '0.5em',
                    }}
                  >
                    <Image
                      src="/logo-ypourgeiou-new-en.png"
                      alt="greek republic ministry of development and investment"
                      width={180}
                      height={70}
                      className={classes.logo}
                      style={{ height: 56, paddingRight: 5, paddingLeft: 5 }}
                    />
                  </Typography>
                </a>
                )}
                <Typography
                  type="title"
                  color="inherit"
                  style={{
                    borderRight: '0.1em solid white',
                    padding: '0.5em',
                  }}
                >
                  <Image
                    src="/img/kee-white.png"
                    alt="central association of Greek chambers of commerce"
                    width={140}
                    height={50}
                    className={classes.logo}
                    style={{ height: 50, paddingRight: 5, paddingLeft: 5 }}
                  />
                </Typography>
              </>
              )}
              <Link href="/" passHref>
                <Typography variant="h6" className={classes.title}>
                  <Button
                    style={{
                      marginLeft: 10,
                      textTransform: 'none',
                      textDecoration: 'none',
                      color: 'white',
                    }}
                    color="inherit"
                  >
                    {phone ? <h2>{t('Αναζήτηση στην Δημοσιότητα ΓΕΜΗ')}</h2> : <h4>{t('Αναζήτηση στην Δημοσιότητα ΓΕΜΗ')}</h4>}
                  </Button>
                </Typography>
              </Link>
              <Button
                id="menu-button"
                color="inherit"
                aria-controls={open ? 'simple-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{ textTransform: 'none' }}
              >
                {currentLanguage === 'el' && (
                <Box display="flex" justifyItems="center" textAlign="center">
                  <Flags.GR
                    title="Greece"
                    style={{ width: '25px', marginRight: '5%' }}
                  />
                  {phone && t('Ελληνικά')}
                </Box>
                )}
                {currentLanguage === 'en' && (
                <Box display="flex" justifyItems="center" textAlign="center">
                  <Flags.US
                    title="English"
                    style={{ width: '25px', marginRight: '5%' }}
                  />
                  {phone && t('English')}
                </Box>
                )}
                {currentLanguage === 'fr' && (
                <Box display="flex" justifyItems="center" textAlign="center">
                  <Flags.FR
                    title="French"
                    style={{ width: '25px', marginRight: '5%' }}
                  />
                  {phone && t('Français')}
                </Box>
                )}
                {currentLanguage === 'ger' && (
                <Box display="flex" justifyItems="center" textAlign="center">
                  <Flags.DE
                    title="German"
                    style={{ width: '25px', marginRight: '5%' }}
                  />
                  {phone && t('Deutsch')}
                </Box>
                )}
                {currentLanguage === 'it' && (
                <Box display="flex" justifyItems="center" textAlign="center">
                  <Flags.IT
                    title="Italian"
                    style={{ width: '25px', marginRight: '5%' }}
                  />
                  {phone && t('Italiano')}
                </Box>
                )}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'menu-button',
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setCurrentLanguage('el');
                      setLanguageFlag(true);
                      i18n.changeLanguage('el');
                      Cookies.set('next-i18next', 'el');
                    }}
                  >
                    <ListItemIcon>
                      <Flags.GR title="Greece" style={{ width: '25px' }} />
                    </ListItemIcon>
                    <ListItemText>{t('Ελληνικά')}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setCurrentLanguage('en');
                      setLanguageFlag(true);
                      i18n.changeLanguage('en');
                      Cookies.set('next-i18next', 'en');
                    }}
                  >
                    <ListItemIcon>
                      <Flags.US title="English" style={{ width: '25px' }} />
                    </ListItemIcon>
                    <ListItemText>{t('English')}</ListItemText>
                  </MenuItem>
                  {process.env.NEXT_PUBLIC_MODE === 'DEV' && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setCurrentLanguage('fr');
                        setLanguageFlag(true);
                        i18n.changeLanguage('fr');
                        Cookies.set('next-i18next', 'fr');
                      }}
                    >
                      <ListItemIcon>
                        <Flags.FR title="French" style={{ width: '25px' }} />
                      </ListItemIcon>
                      <ListItemText>{t('Français')}</ListItemText>
                    </MenuItem>
                  )}
                  {process.env.NEXT_PUBLIC_MODE === 'DEV' && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setCurrentLanguage('ger');
                        setLanguageFlag(true);
                        i18n.changeLanguage('ger');
                        Cookies.set('next-i18next', 'ger');
                      }}
                    >
                      <ListItemIcon>
                        <Flags.DE title="German" style={{ width: '25px' }} />
                      </ListItemIcon>
                      <ListItemText>{t('Deutsch')}</ListItemText>
                    </MenuItem>
                  )}
                  {process.env.NEXT_PUBLIC_MODE === 'DEV' && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setCurrentLanguage('it');
                        setLanguageFlag(true);
                        i18n.changeLanguage('it');
                        Cookies.set('next-i18next', 'it');
                      }}
                    >
                      <ListItemIcon>
                        <Flags.IT title="Italian" style={{ width: '25px' }} />
                      </ListItemIcon>
                      <ListItemText>{t('Italiano')}</ListItemText>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Toolbar>
          </AppBar>
        </div>
        )}
      </Root>
    )
  );
}
