/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ReCaptchaProvider } from 'react-recaptcha-x';
import axios from 'axios';
import Cookies from 'js-cookie';
import { CacheProvider } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import AppHeader from '../src/components/AppHeader';
import AppFooter from '../src/components/AppFooter';
import '../styles/TreeSelectKadMui.css';
import NotVerified from '../src/components/NotVerified';
import '../i18n';
import createEmotionCache from '../src/services/createEmotionCache';
import { elGR, enUS } from '../src/locale/index.ts';
import { captureException } from '@sentry/nextjs';

const reCaptchaV3Key = process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY;
const reCaptchaV2Key = process.env.NEXT_PUBLIC_RECAPTCHA_V2_KEY;
const acceptableScore = process.env.NEXT_PUBLIC_ACCEPTABLE_RECAPTCHA_SCORE;
export const MyContext = createContext();
const clientSideEmotionCache = createEmotionCache();

function MyApp(props) {
  const [verification, setVerification] = useState(true);
  const [languageFlag, setLanguageFlag] = useState(false);
  const {
    Component, emotionCache = clientSideEmotionCache, pageProps, router,
  } = props;

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
      });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const { i18n } = useTranslation();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#556cd6',
      },
    },
  }, i18n.language === 'el' ? elGR : enUS);
  useEffect(() => {
    if (!Cookies.get('next-i18next')) Cookies.set('next-i18next', 'el');
  }, []);

  const verifyV3 = async (token) => {
    try {
      if (typeof token === 'string') {
        const axiosResponse = await axios.post('/api/reCaptchaVerifyV3', {
          token,
        });
        const { score } = axiosResponse.data;
        if (score <= acceptableScore && setVerification) {
          setVerification(false);
        }
      }
    } catch (e) {
      console.error(e);
      console.trace(e);
      captureException(e);
    }
  };

  const [printMode, setPrintMode] = useState(false);
  if(process.env.NEXT_PUBLIC_RECAPTCHA_V3_AVAILABLE==='true' && process.env.NEXT_PUBLIC_RECAPTCHA_V3_AVAILABLE_ON_LOAD==='true') {
    useEffect(() => {
      const interval = setInterval(() => {
        while (typeof window.grecaptcha === 'undefined') {}
        if (typeof window.grecaptcha !== 'undefined') {
          window.grecaptcha.ready(() => {
            window.grecaptcha
              .execute(reCaptchaV3Key, { action: 'onLoad' })
              .then(verifyV3);
          });
        }
      }, 1200); // 120.000 = 2min
      return () => clearInterval(interval);
    }, []);
  }

  return (
    <>
      <Head>
        <title>ΓΕΜΗ :: Υπηρεσίες Δημοσιότητας</title>
        <link rel="shortcut icon" href="/static/gemiFavicon.ico" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${reCaptchaV3Key}`}
        />
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </Head>

      <ReCaptchaProvider
        siteKeyV2={reCaptchaV2Key}
        langCode="en"
        hideV3Badge={false}
      >
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MyContext.Provider
              value={{
                language: Cookies.get('next-i18next'),
                languageFlag,
                setLanguageFlag,
                printMode,
                setPrintMode,
              }}
            >
              {router.query.printMode === 'true' ? (
                <div />
              ) : (
                <AppHeader initialLanguage={Cookies.get('next-i18next')} />
              )}
              {verification ? (
                <div style={{ minHeight: '67.5vh' }}>
                  <Component {...pageProps} />
                </div>
              ) : (
                <NotVerified setVerification={setVerification} />
              )}
              {router.query.printMode === 'true' ? <div /> : <AppFooter />}
            </MyContext.Provider>
          </ThemeProvider>
        </CacheProvider>
      </ReCaptchaProvider>
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/require-default-props
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
export default MyApp;
