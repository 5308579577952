import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';

const main = i18n.createInstance();

main
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // initImmediate: false,
    fallbackLng: Cookies.get('next-i18next') ?? 'el',
    debug: false,
    ns: ['common'],
    defaultNS: 'common',
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
      wait: true,
    },
    backend: {
      // needs base url, else it causes a problem with nodefetch needing absolute url
      loadPath: `${process.env.NEXT_PUBLIC_SYSTEM_URL}locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
