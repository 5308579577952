import * as React from 'react';
import type { ComponentsPropsList } from '../styles/props';

export interface Localization {
  props?: {
    MuiBreadcrumbs?: Pick<ComponentsPropsList['MuiBreadcrumbs'], 'expandText'>;
    MuiTablePagination?: Pick<
      ComponentsPropsList['MuiTablePagination'],
      'backIconButtonText' | 'labelRowsPerPage' | 'labelDisplayedRows' | 'nextIconButtonText'
    >;
    // The core package has no dependencies on the @material-ui/lab components.
    // We can't use ComponentsPropsList, we have to duplicate and inline the definitions.
    MuiRating?: {
      emptyLabelText?: string;
      getLabelText?: (value: number) => string;
    };
    MuiAutocomplete?: {
      clearText?: string;
      closeText?: string;
      loadingText?: React.ReactNode;
      noOptionsText?: React.ReactNode;
      openText?: string;
    };
    MuiAlert?: {
      closeText?: string;
    };
    MuiPagination?: {
      'aria-label'?: string;
      getItemAriaLabel?: (type: string, page: number, selected: boolean) => string | undefined;
    };
  };
}





export const deDE: Localization = {
  props: {
    MuiBreadcrumbs: {
      expandText: 'Pfad anzeigen',
    },
    MuiTablePagination: {
      backIconButtonText: 'Nächste Seite',
      labelRowsPerPage: 'Zeilen pro Seite:',
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} von ${count !== -1 ? count : `more than ${to}`}`,
      nextIconButtonText: 'Nächste Seite',
    },
    MuiRating: {
      getLabelText: (value) => `${value} ${value !== 1 ? 'Sterne' : 'Stern'}`,
      emptyLabelText: 'Keine Wertung',
    },
    MuiAutocomplete: {
      clearText: 'Leeren',
      closeText: 'Schließen',
      loadingText: 'Wird geladen…',
      noOptionsText: 'Keine Optionen',
      openText: 'Öffnen',
    },
    MuiAlert: {
      closeText: 'Schließen',
    },
    MuiPagination: {
      'aria-label': 'Navigation via Seitennummerierung',
      getItemAriaLabel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? '' : 'Gehe zu '}Seite ${page}`;
        }
        if (type === 'first') {
          return 'Zur ersten Seite';
        }
        if (type === 'last') {
          return 'Zur letzten Seite';
        }
        if (type === 'next') {
          return 'Zur nächsten Seite';
        }
        if (type === 'previous') {
          return 'Zur vorherigen Seite';
        }
        return undefined;
      },
    },
  },
};

// default
export const enUS: Localization = {
  /*
  props: {
    MuiBreadcrumbs: {
      expandText: 'Show path',
    },
    MuiTablePagination: {
      backIconButtonText: 'Previous page',
      labelRowsPerPage: 'Rows per page:',
      labelDisplayedRows: ({ from, to, count }) =>
  `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`,
      nextIconButtonText: 'Next page',
    },
    MuiRating: {
      getLabelText: value => `${value} Star${value !== 1 ? 's' : ''}`,
      emptyLabelText: 'Empty',
    },
    MuiAutocomplete: {
      clearText: 'Clear',
      closeText: 'Close',
      loadingText: 'Loading…',
      noOptionsText: 'No options',
      openText: 'Open',
    },
    MuiAlert: {
      closeText: 'Close',
    },
    MuiPagination: {
      'aria-label': 'Pagination navigation',
      getItemAriaLabel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? '' : 'Go to '}page ${page}`;
        }
        if (type === 'first') {
          return 'Go to first page';
        }
        if (type === 'last') {
          return 'Go to last page';
        }
        if (type === 'next') {
          return 'Go to next page';
        }
        if (type === 'previous') {
          return 'Go to previous page';
        }
        return undefined;
      },
    },
  },
*/
};


export const frFR: Localization = {
  props: {
    MuiBreadcrumbs: {
      expandText: 'Montrer le chemin',
    },
    MuiTablePagination: {
      backIconButtonText: 'Page précédente',
      labelRowsPerPage: 'Lignes par page :',
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} sur ${count !== -1 ? count : `plus que ${to}`}`,
      nextIconButtonText: 'Page suivante',
    },
    MuiRating: {
      getLabelText: (value) => `${value} Etoile${value !== 1 ? 's' : ''}`,
      emptyLabelText: 'Vide',
    },
    MuiAutocomplete: {
      clearText: 'Vider',
      closeText: 'Fermer',
      loadingText: 'Chargement…',
      noOptionsText: 'Pas de résultats',
      openText: 'Ouvrir',
    },
    MuiAlert: {
      closeText: 'Fermer',
    },
    MuiPagination: {
      'aria-label': 'navigation de pagination',
      getItemAriaLabel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? '' : 'Aller à la '}page ${page}`;
        }
        if (type === 'first') {
          return 'Aller à la première page';
        }
        if (type === 'last') {
          return 'Aller à la dernière page';
        }
        if (type === 'next') {
          return 'Aller à la page suivante';
        }
        if (type === 'previous') {
          return 'Aller à la page précédente';
        }
        return undefined;
      },
    },
  },
};




export const itIT: Localization = {
  props: {
    MuiBreadcrumbs: {
      expandText: 'Visualizza percorso',
    },
    MuiTablePagination: {
      backIconButtonText: 'Pagina precedente',
      labelRowsPerPage: 'Righe per pagina:',
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} di ${count !== -1 ? count : `more than ${to}`}`,
      nextIconButtonText: 'Pagina successiva',
    },
    MuiRating: {
      getLabelText: (value) => `${value} Stell${value !== 1 ? 'e' : 'a'}`,
      emptyLabelText: 'Vuoto',
    },
    MuiAutocomplete: {
      clearText: 'Svuota',
      closeText: 'Chiudi',
      loadingText: 'Caricamento in corso…',
      noOptionsText: 'Nessuna opzione',
      openText: 'Apri',
    },
    MuiAlert: {
      closeText: 'Chiudi',
    },
    MuiPagination: {
      'aria-label': 'Navigazione impaginata',
      getItemAriaLabel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? '' : 'Vai alla '}pagina ${page}`;
        }
        if (type === 'first') {
          return 'Vai alla prima pagina';
        }
        if (type === 'last') {
          return "Vai all'ultima pagina";
        }
        if (type === 'next') {
          return 'Vai alla pagina successiva';
        }
        if (type === 'previous') {
          return 'Vai alla pagina precedente';
        }
        return undefined;
      },
    },
  },
};

export const elGR : Localization ={
  props: {
    MuiBreadcrumbs: {
      expandText: 'Προβολή Διαδρομής',
    },
    MuiTablePagination: {
      backIconButtonText: 'Προηγούμενη Σελίδα',
      labelRowsPerPage: 'Εγγραφές ανά σελίδα',
      labelDisplayedRows: ({ from, to, count }) =>
  `${from}-${to} από ${count !== -1 ? count : `περισσότερες από ${to}`}`,
      nextIconButtonText: 'Επόμενη Σελίδα',
    },
    MuiRating: {
      getLabelText: value => `${value} Αστέρι${value !== 1 ? 'α' : ''}`,
      emptyLabelText: 'Κενό',
    },
    MuiAutocomplete: {
      clearText: 'Καθαρισμός',
      closeText: 'Κλείσιμο',
      loadingText: 'Επεξεργασία...',
      noOptionsText: 'Καμία Επιλογή',
      openText: 'Άνοιγμα',
    },
    MuiAlert: {
      closeText: 'Κλείσιμο',
    },
    MuiPagination: {
      'aria-label': 'Πλοήγηση Σελίδων',
      getItemAriaLabel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? '' : 'Μετάβαση στη '}σελίδα ${page}`;
        }
        if (type === 'first') {
          return 'Μετάβαση στην πρώτη σελίδα';
        }
        if (type === 'last') {
          return 'Μετάβαση στην τελευταία σελίδα';
        }
        if (type === 'next') {
          return 'Μετάβαση στην επόμενη σελίδα';
        }
        if (type === 'previous') {
          return 'Μετάβαση στην προηγούμενη σελίδα';
        }
        return undefined;
      },
    },
  },
}