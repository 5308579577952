var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_ENV = (process.env.NODE_ENV === 'production' ? "production" : "development");

Sentry.init({
  dsn: 'https://dc2732e2333646b1a1ef1e45d38a5316@o4504769731887104.ingest.sentry.io/4504769736802304',
  environment: SENTRY_ENV,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  enabled: process.env.NODE_ENV !== 'development',
});
