import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaV2,
} from 'react-recaptcha-x';
import axios from 'axios';

export default function NotVerified(props) {
  const { setVerification } = props;
  const v2Callback = async (token) => {
    if (typeof token === 'string') {
      const axiosResponse = await axios.post('/api/reCaptchaVerifyV2', {
        token,
      });
      const { success } = axiosResponse.data;
      if (success && setVerification) {
        setVerification(true);
      }
    }
  };
  return (
    <Container
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: '10%',
        minHeight: '85vh',
      }}
    >
      <Box
        marginBottom="20%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography
          variant="h5"
          style={{ textAlign: 'center', marginBottom: '10%' }}
        >
          {' '}
          Σας έχουμε αναγνωρίσει ως ρομπότ !
        </Typography>

        <ReCaptchaV2
          callback={v2Callback}
          theme={EReCaptchaV2Theme.Light}
          size={EReCaptchaV2Size.Normal}
          id="my-id"
          data-test-id="my-test-id"
          tabindex={0}
        />
      </Box>
    </Container>
  );
}
